import { createTheme } from '@mui/material/styles';

const mobileBreakpoint = 600;

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: mobileBreakpoint,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          [`@media (max-width:${mobileBreakpoint}px)`]: {
            fontSize: '2rem',
          },
        },
        h2: {
          [`@media (max-width:${mobileBreakpoint}px)`]: {
            fontSize: '1.75rem',
          },
        },
        h3: {
          [`@media (max-width:${mobileBreakpoint}px)`]: {
            fontSize: '1.5rem',
          },
        },
        h4: {
          [`@media (max-width:${mobileBreakpoint}px)`]: {
            fontSize: '1.25rem',
          },
        },
        h5: {
          [`@media (max-width:${mobileBreakpoint}px)`]: {
            fontSize: '1rem',
          },
        },
        h6: {
          [`@media (max-width:${mobileBreakpoint}px)`]: {
            fontSize: '0.875rem',
          },
        },
        subtitle1: {
          [`@media (max-width:${mobileBreakpoint}px)`]: {
            fontSize: '0.8125rem',
          },
        },
        subtitle2: {
          [`@media (max-width:${mobileBreakpoint}px)`]: {
            fontSize: '0.75rem',
          },
        },
        body1: {
          [`@media (max-width:${mobileBreakpoint}px)`]: {
            fontSize: '0.875rem',
          },
        },
        body2: {
          [`@media (max-width:${mobileBreakpoint}px)`]: {
            fontSize: '0.8125rem',
          },
        },
      },
    },
  },
});

export default theme;
