import React from 'react';

export const convertNewlinesToBreaks = (text) => {
  const lines = text.split('\n');
  return lines.map((line, index) => {
    // Calculate the indentation level of the current line
    const indentation = line.match(/^\s*/)[0];

    return (
      <React.Fragment key={index}>
        {indentation}
        {line.trim()}{' '}
        {/* Render the line content after removing leading/trailing whitespace */}
        {index !== lines.length - 1 && <br />}{' '}
        {/* Add <br /> except for the last line */}
      </React.Fragment>
    );
  });
};

export const calculateRowHeight = () => {
  const viewportHeight = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  );
  const parentHeightVh = viewportHeight * 0.5;

  return Math.floor(parentHeightVh / 19);
};
