import React from 'react';
import {
  Container,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  Grid,
  Avatar,
  CardMedia,
  Fade,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import goodNews from '../../assets/icons/good_news.jpg';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { Link } from 'react-router-dom';

const linkStyle = {
  color: 'inherit',
  textDecoration: 'none',
};

function Home() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Header />
      <Fade in={true} timeout={1000}>
        <Container maxWidth="md">
          <header>
            <Box mt={4} textAlign="center">
              <Typography variant="h2" component="h1" gutterBottom>
                SwiftCover | AI-Powered Cover Letter Generator
              </Typography>
              <Typography variant="h5" component="h2">
                Transform Your Cover Letter in Seconds
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Link to="/create" style={linkStyle}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                      fontWeight: 'bold',
                      '&:hover': {
                        backgroundColor: 'blue',
                        transform: 'scale(1.05)',
                      },
                    }}
                  >
                    Generate Cover Letter
                  </Button>
                </Link>
                <CardMedia
                  component="img"
                  height="240"
                  image={goodNews}
                  alt="a job applicant creating an AI generated cover letter"
                  sx={{ m: '1.5rem 0' }}
                />
              </Box>
            </Box>
          </header>

          <main>
            <Box my={4}>
              <Card sx={{ borderRadius: 3, boxShadow: 3, marginBottom: 2 }}>
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    align="center"
                    variant="h4"
                    component="h3"
                    gutterBottom
                  >
                    Land Your Dream Job with the Perfect Cover Letter
                  </Typography>
                  <Typography align="center">
                    SwiftCover is a state of the art AI tool used to craft
                    tailored cover letters that fit any job description within
                    seconds. No more tedious editing and tweaking - let our AI
                    do the work for you!
                  </Typography>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minWidth: '200px',
                      }}
                    >
                      <Box mt={2} mb={1}>
                        <Avatar sx={{ backgroundColor: 'primary.main' }}>
                          <UploadFileIcon color="white" />
                        </Avatar>
                      </Box>
                      <Typography align="center" sx={{ fontWeight: 'bold' }}>
                        Paste your existing cover letter
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minWidth: '200px',
                      }}
                    >
                      <Box mt={2} mb={1}>
                        <Avatar sx={{ backgroundColor: 'primary.main' }}>
                          <BusinessIcon color="white" />
                        </Avatar>
                      </Box>
                      <Typography align="center" sx={{ fontWeight: 'bold' }}>
                        Input the company description
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minWidth: '200px',
                      }}
                    >
                      <Box mt={2} mb={1}>
                        <Avatar sx={{ backgroundColor: 'primary.main' }}>
                          <WorkIcon color="white" />
                        </Avatar>
                      </Box>
                      <Typography align="center" sx={{ fontWeight: 'bold' }}>
                        Input the job description
                      </Typography>
                    </Grid>
                    <Box sx={{ marginTop: '1rem' }}>
                      <a
                        href="https://theresanaiforthat.com/ai/swiftcover/?ref=featured&v=125242"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <img
                          width="200"
                          src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=600"
                          alt=""
                        />
                      </a>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </main>
        </Container>
      </Fade>
      <Footer />
    </Box>
  );
}

export default Home;
