import React from 'react';
import { auth } from '../../firebase/firebase';
import {
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Modal, Button } from '@mui/material';
import { Facebook, GitHub, Google } from '@mui/icons-material';
import logo from '../../assets/icons/writing.png';

// firebase auth
const googleProvider = new GoogleAuthProvider();
const githubProvider = new GithubAuthProvider();
const facebookProvier = new FacebookAuthProvider();

export default function AuthenticationModal({ open, handleClose }) {
  const signInWithProvidorFunction = async (provider) => {
    try {
      const result = await signInWithPopup(auth, provider);

      if (result) {
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
          borderRadius: '10px',
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          textAlign="center"
          variant="h4"
          component="h6"
          sx={{ m: 1, whiteSpace: 'nowrap' }}
        >
          Welcome to SwiftCover
        </Typography>
        <img
          src={logo}
          alt="SwiftCover Logo"
          width="70"
          height="70"
          style={{ margin: '.75rem' }}
        />
        <Typography
          textAlign="center"
          id="modal-modal-description"
          sx={{ m: 1 }}
        >
          Please continue by logging in with any of the following providers
        </Typography>
        <Button
          sx={{
            margin: 1,
            fontWeight: 600,
            color: 'black',
            borderColor: 'black',
            width: '90%',
            '& .MuiSvgIcon-root': {
              color: 'orange',
            },
            '&:hover': {
              borderColor: 'orange',
              background: 'orange',
              color: 'white',
              '& .MuiSvgIcon-root': {
                color: 'white',
              },
            },
          }}
          color="primary"
          size="large"
          variant="outlined"
          startIcon={<Google />}
          onClick={() => signInWithProvidorFunction(googleProvider)}
        >
          Continue with Google
        </Button>
        <Button
          sx={{
            margin: 1,
            fontWeight: 600,
            width: '90%',
            color: 'black',
            borderColor: 'black',
            '& .MuiSvgIcon-root': {
              color: 'black',
            },
            '&:hover': {
              borderColor: 'black',
              background: 'black',
              color: 'white',
              '& .MuiSvgIcon-root': {
                color: 'white',
              },
            },
          }}
          color="primary"
          size="large"
          variant="outlined"
          startIcon={<GitHub />}
          onClick={() => signInWithProvidorFunction(githubProvider)}
        >
          Continue with GitHub
        </Button>
        {/* <Button
          sx={{
            margin: 1,
            fontWeight: 600,
            width: '90%',
            color: 'black',
            borderColor: 'black',
            '& .MuiSvgIcon-root': {
              color: 'blue',
            },
            '&:hover': {
              borderColor: 'blue',
              background: 'blue',
              color: 'white',
              '& .MuiSvgIcon-root': {
                color: 'white',
              },
            },
          }}
          color="primary"
          size="large"
          variant="outlined"
          startIcon={<Facebook />}
          onClick={() => signInWithProvidorFunction(facebookProvier)}
        >
          Continue with FaceBook
        </Button> */}
      </Box>
    </Modal>
  );
}
