import React, { useState } from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase/firebase';

const live =
  'pk_live_51NEChCIBWpsMVwJcJckA6OkCIOrTYRUlIY86SPXd6twgAXOELKqMBdkEP1SkeArNcerAFoEhKTmB5ZKPwDM0Kwjk00tiM5H7AA';

const stripePromise = loadStripe(live);

export default function ProductCard({ product }) {
  const [isLoading, setIsLoading] = useState(false); // Manage loading state

  const handleCheckout = async (priceId, credits, subscription = false) => {
    setIsLoading(true); // Start loading
    const stripe = await stripePromise;
    console.log('stripe env', stripe._keyMode);

    const checkoutType = subscription
      ? 'createStripeSubscriptionSession'
      : 'createStripeCheckoutSession';
    const createStripeCheckoutSession = httpsCallable(functions, checkoutType);

    try {
      const response = await createStripeCheckoutSession({ priceId, credits });
      // When the checkout session is created, redirect the user to Stripe Checkout
      const result = await stripe.redirectToCheckout({
        sessionId: response.data.id,
      });

      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <Card sx={{ width: { xs: '100%', md: 320 }, position: 'relative' }}>
      <CardMedia
        component="img"
        height="220"
        image={product.image}
        alt={product.title}
      />
      {product.isBestDeal && (
        <Box
          sx={{
            position: 'absolute',
            top: '.75rem',
            left: '-1.75rem',
            backgroundColor: '#ee7600',
            color: 'white',
            padding: '0.75rem',
            transform: 'rotate(-45deg)',
            fontSize: '1rem',
            width: '8rem',
            textAlign: 'center',
            textTransform: 'uppercase',
            boxShadow: 2,
            zIndex: 1,
          }}
        >
          Best Deal
        </Box>
      )}
      {product?.oldPrice && (
        <Typography
          variant="h6"
          component="div"
          sx={{
            position: 'absolute',
            top: '0',
            right: '.3rem',
            textDecoration: 'line-through',
          }}
        >
          {product.oldPrice}
        </Typography>
      )}
      <CardContent>
        <Typography variant="h5" component="div">
          {product.title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3" component="span">
            {product.price}
          </Typography>
          {product.isSubscription && (
            <Typography
              variant="body1"
              component="span"
              sx={{ marginLeft: '0.5rem', lineHeight: 1 }}
            >
              per month
            </Typography>
          )}
        </Box>
        <Typography gutterBottom variant="body2" color="text.secondary">
          {product.description}
        </Typography>
        <Button
          variant="contained"
          target="_blank"
          rel="noreferrer"
          sx={{ m: 1 }}
          onClick={() =>
            handleCheckout(product.priceId, product.credits, product.isBestDeal)
          }
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Buy Now'}
        </Button>
      </CardContent>
    </Card>
  );
}
