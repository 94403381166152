import React, { useContext } from 'react';
import ProductCard from '../../components/ProductCard/ProductCard';
import happyMan from '../../assets/icons/happy.png';
import sittingMan from '../../assets/icons/sitting.png';
import phoneMan from '../../assets/icons/phone.png';
import subscriptionTier from '../../assets/icons/subscriptiontier.webp';
import { Stack, Container, Box, Grow } from '@mui/material';
import { AuthContext } from '../../context/authcontext';
import SubscriptionCard from '../../components/SubscriptionCard/SubscriptionCard';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

export default function Products() {
  const { currentUser } = useContext(AuthContext);
  const { isSubscribed, cancel_at_period_end } = currentUser || {};
  const products = [
    {
      image: phoneMan,
      title: '3 Credits',
      price: '$2.99',
      description:
        'One credit is used to create a cover letter. You can increase the quantity at checkout',
      priceId: 'price_1NbDi0IBWpsMVwJcwn9PaCYC',
      credits: 3,
    },
    {
      image: sittingMan,
      title: '25 Credits',
      price: '$18.99',
      oldPrice: '$24.75',
      description:
        'Value pack of 25 credits to generate cover letters, save 15%!',
      priceId: 'price_1NF6IAIBWpsMVwJcJw4sZf6B',
      credits: 25,
    },
    {
      image: happyMan,
      title: '50 Credits',
      price: '$29.99',
      oldPrice: '$49.50',
      description:
        'Value pack of 50 credits to generate cover letters, save 40%!',
      priceId: 'price_1NF6J4IBWpsMVwJceNE0YlTB',
      // priceId: 'price_1NEE5QIBWpsMVwJcJFvF2yMZ', // this is the test id
      credits: 50,
    },
    {
      image: subscriptionTier,
      title: 'Unlimited',
      price: '$9.99',
      isSubscription: true,
      description:
        'Unlimited credits. Monthly subscription. Best value! Previously purchased credits remain.',
      // priceId: 'price_1PqHKtIBWpsMVwJc4YGuBhe7', // this is the test id
      priceId: 'price_1PqGZpIBWpsMVwJc8XdB5aL3',
      credits: 0,
      isBestDeal: true,
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: { sm: '80vh', xs: 'auto' },
          padding: { xs: '2rem' },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SubscriptionCard />
        {!(isSubscribed || cancel_at_period_end) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Stack
              spacing={2}
              textAlign="center"
              direction={{ xs: 'column-reverse', sm: 'row' }}
              // flexWrap="wrap"
            >
              {products.map((product, index) => (
                <Grow
                  key={product.title}
                  in={true}
                  style={{ transformOrigin: 'center bottom' }}
                  {...(true ? { timeout: 1500 * (index + 1) } : {})}
                >
                  <div>
                    <ProductCard product={product} />
                  </div>
                </Grow>
              ))}
            </Stack>
          </Box>
        )}
      </Container>
      <Footer />
    </Box>
  );
}
