import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Link,
  Box,
  Divider,
  TextField,
  CircularProgress,
} from '@mui/material';
import sadCatMeme from '../../assets/meme/sadcat.jpg';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/authcontext';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase/firebase';

function HelpDialog({ open, onClose }) {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState('default'); // 'default', 'confirmUnsubscribe', 'feedback'

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleCancelSubscription = () => {
    setStep('confirmUnsubscribe');
  };

  const handleConfirmUnsubscribe = () => {
    setStep('feedback');
  };

  const handleClose = () => {
    onClose();
    reset();
    setStep('default');
  };

  const handleUnsubscribe = async (payload) => {
    const deleteStripeSubscription = httpsCallable(
      functions,
      'deleteStripeSubscription',
    );
    try {
      setIsLoading(true);
      const response = await deleteStripeSubscription(payload);
      const { data } = response || {};
      if (data.success) {
        setCurrentUser(data.userData);
      }
    } catch (error) {
      console.log(`Error deleting subscription: ${error}`);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: { padding: '.5rem', borderRadius: '12px' },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h5"
          component="div"
          sx={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          Help & Support
        </Typography>
      </DialogTitle>
      <DialogContent>
        {step === 'default' && (
          <Box>
            <Typography
              gutterBottom
              sx={{ textAlign: 'center', marginBottom: '1rem' }}
            >
              How can we assist you?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <Link
                href="https://enriquebarco.com"
                target="_blank"
                rel="noopener"
                sx={{ fontSize: '1rem' }}
              >
                Submit Feedback
              </Link>
              <Link
                href="https://enriquebarco.com"
                target="_blank"
                rel="noopener"
                sx={{ fontSize: '1rem' }}
              >
                Request Features
              </Link>
              <Divider sx={{ width: '100%', marginY: '1rem' }} />
              {currentUser?.isSubscribed && (
                <Button
                  onClick={handleCancelSubscription}
                  color="error"
                  variant="outlined"
                  size="small"
                  sx={{
                    fontSize: '0.6rem',
                    padding: '0.2rem 0.5rem',
                    minWidth: 'auto',
                  }}
                >
                  Cancel Subscription
                </Button>
              )}
            </Box>
          </Box>
        )}

        {step === 'confirmUnsubscribe' && (
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body1">
              Are you sure you want to unsubscribe?
            </Typography>
            <Box
              sx={{
                marginTop: '1rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src={sadCatMeme}
                alt="sad cat meme"
                style={{
                  maxWidth: '100%',
                  height: '200',
                  borderRadius: '8px',
                }}
              />
            </Box>
            <Box sx={{ marginTop: '1rem' }}>
              <Button variant="outlined" onClick={() => setStep('default')}>
                No
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={handleConfirmUnsubscribe}
                sx={{ marginLeft: '0.5rem' }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        )}

        {step === 'feedback' && (
          <Box
            component="form"
            onSubmit={handleSubmit(handleUnsubscribe)}
            sx={{ textAlign: 'center' }}
          >
            <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
              We're sad to see you go. Why do you want to unsubscribe?
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              placeholder="Your feedback"
              {...register('feedback', { required: 'Feedback is required' })}
              error={!!errors.feedback}
              helperText={errors.feedback ? errors.feedback.message : ''}
              sx={{ marginBottom: '1rem' }}
            />
            <Button variant="contained" color="error" type="submit" sx={{minWidth: '150px', fontSize: "0.75rem"}} size="small" >
              {isLoading ? <CircularProgress size={24} sx={{color: 'white'}} /> : 'Unsubscribe'}
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default HelpDialog;
