import React, { createContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { doc, getDoc } from 'firebase/firestore';
import { db, functions } from '../firebase/firebase';
import LoadingUserModal from '../components/LoadingUserModal/LoadingUserModal';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [prevLetter, setPrevLetter] = useState(null);
  const [refinedLetter, setRefinedLetter] = useState(null);

  // modal
  const [open, setOpen] = useState(false);

  function isNewUser(createdAtStr, lastLoginAtStr) {
    // Parse string time to Date object
    let createdAt = new Date(createdAtStr);
    let lastLoginAt = new Date(lastLoginAtStr);

    // If createdAt and lastLoginAt are same, then it's a new user
    if (createdAt.getTime() === lastLoginAt.getTime()) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      // since firebase is asyncronous, and provides no built in way to know if createUser is complete, we need to wait a bit before continuing the function
      if (user) {
        // start loading modal
        setOpen(true);

        const letterId = localStorage.getItem('letterId');
        const userId = user.uid;
        const handleLtterId = httpsCallable(functions, 'handleLetterId');
        const createUser = httpsCallable(functions, 'createUser');

        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);

        // first determine if this is a new user, and create a user if so
        const { creationTime } = user.metadata;
        const newUser = !userSnap.exists();

        // if a new user, create a user document in the database
        if (newUser) {
          console.log('new user being created');
          // onCall
          try {
            await createUser({ creationTime });
          } catch (error) {
            console.log(error);
          }
        }

        // if previously created a cover letter without authenticating, save data and remove credits
        if (letterId) {
          try {
            const data = await handleLtterId({ letterId, userId });
            const userData = data.data.userData;
            const { prevLetter, generatedLetter } = userData;

            // set user info based on data returned from handleLetterId, no need to refetch
            setCurrentUser(userData);
            setPrevLetter(prevLetter);
            setRefinedLetter(generatedLetter);

            // remove letterId from local storage
            localStorage.removeItem('letterId');
          } catch (error) {
            console.log(error);
          }
          // if user is logging in without having created an authenticated cover letter, we can get their data from the database
        } else {
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            if (userData) {
              setCurrentUser(userData);
              setPrevLetter(userData.prevLetter || '');
              setRefinedLetter(userData.generatedLetter || '');
            } else {
              console.log('No user data returned from Firestore.');
            }
          } else {
            console.log('No such user document!');
          }
        }
        // close loading modal
        setOpen(false);
      } else {
        // User is signed out.
        setCurrentUser(null);
        setPrevLetter(null);
        setRefinedLetter(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <>
      <AuthContext.Provider
        value={{
          currentUser,
          setCurrentUser,
          prevLetter,
          setPrevLetter,
          refinedLetter,
          setRefinedLetter,
        }}
      >
        {children}
      </AuthContext.Provider>
      <LoadingUserModal open={open} handleClose={() => setOpen(false)} />
    </>
  );
};
