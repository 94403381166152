import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  Stack,
  Button,
} from '@mui/material';

import Snake from '../Snake/Snake';

export default function LoadingModal({ open, handleClose, error }) {
  const [snake, setSnake] = useState(false);

  const handleCloseAll = () => {
    handleClose();
    setSnake(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setSnake(false)}
      aria-labelledby="Loading Modal"
      aria-describedby="A modal to display while loading the generated cover letter."
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'fit-content',
          maxWidth: '90vw',
          bgcolor: 'background.paper',
          boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
          borderRadius: '10px',
          padding: '1.5rem',
        }}
      >
        {error ? (
          <Stack alignItems="center" justifyContent="center">
            <Typography
              variant="h5"
              component="p"
              sx={{ marginBottom: '1rem' }}
            >
              Sorry, there was an error generating your cover letter:
            </Typography>
            <Typography
              variant="h5"
              component="p"
              sx={{ marginBottom: '1rem' }}
            >
              {error}
            </Typography>
            <Button
              onClick={() => {
                handleCloseAll();
                window.location.href = '/purchase';
              }}
              variant="outlined"
              color="primary"
              sx={{ m: 1 }}
            >
              Purchase Credits
            </Button>
          </Stack>
        ) : (
          <>
            <Typography
              variant="h4"
              component="h5"
              sx={{ marginBottom: '1rem' }}
            >
              Creating your cover letter...
            </Typography>
            {!snake ? (
              <Box
                sx={{
                  position: 'relative',
                  display: 'inline-flex',
                  m: '4rem 0',
                  cursor: 'pointer',
                }}
                onClick={() => setSnake(true)}
              >
                <CircularProgress size={200} />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    sx={{ width: '5rem', fontWeight: 'bold' }}
                    textAlign="center"
                    component="div"
                  >
                    meanwhile, a game of snake?
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Snake setSnake={setSnake} />
            )}
            {/* <img
              src={loading}
              alt="Cool Pen Smoke Writer Cartoon Illustration Animated Gif"
              height="400"
            /> */}
            <Typography
              textAlign="center"
              variant="subtitle1"
              component="h6"
              sx={{ marginTop: '1rem', width: '25rem', fontWeight: 'bold' }}
            >
              "83% of hiring managers reported that cover letters are important
              in their hiring decisions" - ResumeLab, 2019
            </Typography>
          </>
        )}
      </Box>
    </Modal>
  );
}
