import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/authcontext';
import { getAuth, signOut } from 'firebase/auth';
import logo from '../../assets/icons/writing.png';
import { MonetizationOn } from '@mui/icons-material';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Badge,
  Box,
  Fade,
} from '@mui/material';
import AuthenticationModal from '../AuthenticationModal/AuthenticationModal';
import { Link } from 'react-router-dom';

function Header() {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const linkStyle = {
    color: 'inherit',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  };

  const handleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  };

  return (
    <Fade in={true} timeout={1000}>
      <AppBar position="static">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link to="/" style={linkStyle}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ m: 1, padding: 0 }}
            >
              <img src={logo} alt="SwiftCover Logo" width="50" height="50" />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            >
              SwiftCover
            </Typography>
          </Link>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {currentUser ? (
              <>
                {currentUser.isSubscribed ? (
                  <Tooltip
                    title={`You have are subscribed`}
                    arrow
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        fontSize: '.75rem',
                      },
                    }}
                  >
                    <Link to="/purchase" style={linkStyle}>
                      <IconButton sx={{ padding: 0 }} size="large">
                        <AllInclusiveIcon
                          fontSize="large"
                          style={{ color: 'gold' }}
                        />
                      </IconButton>
                    </Link>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={`You have ${currentUser.credits} credits remaining`}
                    arrow
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        fontSize: '.75rem',
                      },
                    }}
                  >
                    <Link to="/purchase" style={linkStyle}>
                      <IconButton sx={{ padding: 0 }} size="large">
                        <Badge
                          badgeContent={currentUser.credits}
                          overlap="circular"
                          color="error"
                        >
                          <MonetizationOn
                            fontSize="large"
                            style={{ color: 'white' }}
                          />
                        </Badge>
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                <Button color="inherit" onClick={handleSignOut}>
                  Sign Out
                </Button>
              </>
            ) : (
              <Button color="inherit" onClick={handleOpen}>
                Register / Sign in
              </Button>
            )}
          </Box>
        </Toolbar>
        <AuthenticationModal open={open} handleClose={handleClose} />
      </AppBar>
    </Fade>
  );
}

export default Header;
