import React from 'react';
import { Box, Modal, Button, Typography, TextField } from '@mui/material';
import { calculateRowHeight } from '../../utils/utils';
export default function RefineLetterModal({
  open,
  handleClose,
  handleRefineLetter,
  userEdits,
  setUserEdits,
}) {
  const textFieldStyle = {
    width: '100%',
    '& .MuiFormHelperText-root': {
      marginBottom: '.5rem',
      textAlign: 'center',
      margin: '.5rem 0',
      fontSize: { xs: '0.55rem', sm: '.75rem', md: '1rem' },
      width: '100%',
    },
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Loading Modal"
      aria-describedby="A modal to display while loading the generated cover letter."
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'fit-content',
          maxWidth: '90vw',
          minWidth: '16rem',
          bgcolor: 'background.paper',
          boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
          borderRadius: '10px',
          padding: '1.5rem',
        }}
      >
        <Typography variant="h5" component="p" sx={{ marginBottom: '1rem' }}>
          Refine Your Cover Letter
        </Typography>
        <TextField
          label="What would you like to change?"
          defaultValue={userEdits}
          placeholder='For example, "I would like to restructure the format of the cover letter so it starts with my experience, furthermore, highlight xyz milestones."'
          helperText={`${
            userEdits?.length === 3500
              ? '4000/4000 Char. You have reached the maximum amount of characters'
              : "Please specify how you'd like to enhance your cover letter. This service uses 0.5 credits"
          }`}
          multiline
          rows={calculateRowHeight()}
          variant="outlined"
          sx={textFieldStyle}
          onChange={(e) => setUserEdits(e.target.value)}
          inputProps={{
            maxLength: 3500,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleRefineLetter}
          sx={{
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'blue',
              transform: 'scale(1.05)',
            },
          }}
        >
          Refine Cover Letter
        </Button>
      </Box>
    </Modal>
  );
}
