import React from 'react';
import { Box, Typography, Button, CardMedia, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import goodNews from '../../assets/icons/good_news.jpg';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const linkStyle = {
  color: 'inherit',
  textDecoration: 'none',
};

function ThankYou() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Header />

      <Stack
        spacing={2}
        direction="column"
        alignItems="center"
        sx={{
          maxWidth: '60rem',
          margin: 'auto',
          padding: '1rem',
        }}
      >
        <CardMedia
          component="img"
          height="240"
          image={goodNews}
          alt="a job applicant creating an AI generated cover letter"
          sx={{ m: '1.5rem 0' }}
        />
        <Typography variant="h5" component="h2">
          Your purchase was succesful 🙂
        </Typography>
        <Link to="/create" style={linkStyle}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: 'blue',
                transform: 'scale(1.05)',
              },
            }}
          >
            Generate Cover Letter
          </Button>
        </Link>
      </Stack>
      <Footer />
    </Box>
  );
}

export default ThankYou;
