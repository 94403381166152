import React, { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import HelpDialog from '../HelpDialog/HelpDialog'; // Adjust the import path as necessary

const Footer = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        alignItems="center"
        justifyContent="center"
        padding={2}
        bgcolor="primary.main"
        color="white"
        marginTop={'auto'}
      >
        <Box display="flex" flexDirection="row" gap={2}>
          <Link
            href="https://www.linkedin.com/company/swiftcover-ai/"
            variant="body2"
            color="inherit"
            target="_blank"
            rel="noopener noreferrer"
          >
            About Us
          </Link>
          <Link
            href="https://swiftcover.notion.site/Privacy-Policy-e30255f7d36c4e808a91a188328215ad?pvs=4"
            variant="body2"
            color="inherit"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          <Link
            variant="body2"
            component={'button'}
            color="inherit"
            sx={{ cursor: 'pointer' }}
            onClick={handleClickOpen}
          >
            Help & Support
          </Link>
        </Box>
      </Box>
      <HelpDialog open={open} onClose={handleClose} />
    </>
  );
};

export default Footer;
