import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyDXyHwvb4qOPLEI_F9LIKDhWexWu7mtC7Q',
  authDomain: 'swiftcover-d96d7.firebaseapp.com',
  projectId: 'swiftcover-d96d7',
  storageBucket: 'swiftcover-d96d7.appspot.com',
  messagingSenderId: '1024796894386',
  appId: '1:1024796894386:web:37d200c9f62cd4e539d5ab',
  measurementId: 'G-XT5MJW0QCE',
};

const app = initializeApp(firebaseConfig);

// if (process.env.NODE_ENV === 'development') {
//   const auth = getAuth(app);
//   connectAuthEmulator(auth, 'http://localhost:9099');
// }

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
const analytics = getAnalytics(app);

// connectFunctionsEmulator(functions, 'localhost', 5001);
// connectFirestoreEmulator(db, 'localhost', 8080);

export default app;
