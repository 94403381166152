import React, { useContext, useState } from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import { AuthContext } from '../../context/authcontext';
import dayjs from 'dayjs';
import HelpDialog from '../HelpDialog/HelpDialog';
import sadCatMeme from "../../assets/meme/sadcat.jpg";
import subscriptionTier from "../../assets/icons/subscriptiontier.webp";
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase/firebase';

function SubscriptionCard() {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const { isSubscribed, cancel_at_period_end } = currentUser || {};
  const [isHelpOpened, setIsHelpOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRenewDialogOpened, setIsRenewDialogOpened] = useState(false);

  const handleRenewConfirm = async () => {
    // Handle the subscription renewal logic here
    const renewStripeSubscription = httpsCallable(functions, "renewStripeSubscription");
    try {
      setIsLoading(true)
      const response = await renewStripeSubscription();
      const { data } = response || {}
      if (data.success) {
        setCurrentUser(data.userData)
      }
    } catch (error) {
      console.error(`Error renewing subscription: ${error}`);
    } finally {
      setIsLoading(false)
      setIsRenewDialogOpened(false);
    }
  };

  return (
    <>
      {isSubscribed && (
        <Card sx={{ width: '100%', textAlign: 'center', maxWidth: '350px' }}>
          <CardMedia
            component="img"
            height="400"
            image={cancel_at_period_end ? sadCatMeme : subscriptionTier}
            alt={cancel_at_period_end ? 'sad cat meme' : 'Subscription Tier'}
          />
          <CardContent>
            {cancel_at_period_end ? (
              <>
                <Typography variant="h5" component="div">
                  Subscription Cancelled...😭
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Ends:{' '}
                  {dayjs(currentUser.subscriptionEndDate).format(
                    'MMMM D, YYYY',
                  )}
                </Typography>
                {currentUser.credits && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Credits: {currentUser.credits}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  onClick={() => setIsRenewDialogOpened(true)}
                  size="small"
                >
                  Renew Subscription
                </Button>
              </>
            ) : (
              <>
                <Typography variant="h5" component="div">
                  Subscribed 😎
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Next Payment:{' '}
                  {dayjs(currentUser.subscriptionEndDate).format(
                    'MMMM D, YYYY',
                  )}
                </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Credits: {currentUser.credits}
                  </Typography>
                <Button
                  variant="outlined"
                  sx={{
                    fontSize: '0.75rem',
                  }}
                  onClick={() => setIsHelpOpened(true)}
                  size="small"
                >
                  Help
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      )}
      <HelpDialog open={isHelpOpened} onClose={() => setIsHelpOpened(false)} />
      
      <Dialog maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: { padding: '1rem', borderRadius: '12px' },
      }}
      open={isRenewDialogOpened} onClose={() => setIsRenewDialogOpened(false)}>
        <DialogTitle>Renew Subscription</DialogTitle>
        <DialogContent>
          <CardMedia
            component="img"
            height="350"
            image={subscriptionTier}
            alt="Subscription Tier"
          />
          <DialogContentText sx={{marginTop: '1rem'}}>
            By renewing your subscription, you agree to be charged again at the next payment period.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRenewDialogOpened(false)}>Cancel</Button>
          <Button onClick={handleRenewConfirm} variant="contained" color="primary">
            {isLoading ? <CircularProgress size={24} sx={{color: 'white'}} /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SubscriptionCard;
