import React from 'react';
import { Modal, Box, Typography, CircularProgress } from '@mui/material';

export default function LoadingUserModal({ open, handleClose }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Loading Modal"
      aria-describedby="A modal to display while loading the generated cover letter."
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'fit-content',
          maxWidth: '90vw',
          bgcolor: 'background.paper',
          boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
          borderRadius: '10px',
          padding: '1.5rem',
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          Signing you in...
        </Typography>
        <CircularProgress />
      </Box>
    </Modal>
  );
}
