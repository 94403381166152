import React, { useContext, useState } from 'react';
import Home from './pages/Home/Home.js';
import Box from '@mui/material/Box';
import ExistingLetter from './pages/ExistingLetter/ExistingLetter';
import LoadingModal from './components/LoadingModal/LoadingModal.js';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase/firebase';
import AuthenticationModal from './components/AuthenticationModal/AuthenticationModal.js';
import { AuthContext } from './context/authcontext.js';
import Products from './pages/Products/Products.js';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme.js';
import RefineLetterModal from './components/RefineLetterModal/RefineLetterModal.js';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ThankYou from './pages/ThankYou/ThankYou.js';

function App() {
  // state for cover letter creation
  const [jobInfo, setJobInfo] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  // state for refining cover letter
  const [userEdits, setUserEdits] = useState(null);
  const {
    prevLetter,
    setPrevLetter,
    setCurrentUser,
    refinedLetter,
    setRefinedLetter,
  } = useContext(AuthContext);

  // modals
  const [openAuthenticationModal, setOpenAuthenticationModal] = useState(false);
  const [openRefineLetterModal, setOpenRefineLetterModal] = useState(false);
  // loading
  const [loading, setLoading] = useState(false);

  // error
  const [error, setError] = useState(null);

  const handleCloseLoading = () => {
    setLoading(false);
    setError(null);
  };

  const handleSubmit = async () => {
    if (localStorage.getItem('letterId')) {
      // if letterId exists, open authentication modal
      return setOpenAuthenticationModal(true);
    }

    setLoading(true);
    const createCoverLetter = httpsCallable(functions, 'createCoverLetter');
    try {
      const data = await createCoverLetter({
        prevLetter,
        jobInfo,
        companyInfo,
      });
      const response = data.data;
      const error = response.error;
      if (error) {
        // if error, set error state and end function
        return setError(error);
      }

      if (response.newUserData) {
        // set user info into context
        setCurrentUser(response.newUserData);
        setRefinedLetter(response.newUserData.generatedLetter);
        setPrevLetter(response.newUserData.prevLetter);
      }

      if (response.letterId) {
        // set letterId in local storage
        setRefinedLetter(response.generatedLetter);
        localStorage.setItem('letterId', response.letterId);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleRefineLetter = async () => {
    if (!refinedLetter) {
      return;
    } else {
      setOpenRefineLetterModal(false);
      setLoading(true);
      const refineCoverLetter = httpsCallable(functions, 'refineCoverLetter');
      try {
        const data = await refineCoverLetter({
          prevLetter: refinedLetter,
          userEdits,
        });
        const response = data.data;
        const error = response.error;
        if (error) {
          // if error, set error state and end function
          return setError(error);
        }

        if (response.newUserData) {
          // set user info into context
          setCurrentUser(response.newUserData);
          setRefinedLetter(response.newUserData.generatedLetter);
          setPrevLetter(response.newUserData.prevLetter);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/create',
      element: (
        <ExistingLetter
          refinedLetter={refinedLetter}
          setPrevLetter={setPrevLetter}
          setJobInfo={setJobInfo}
          setCompanyInfo={setCompanyInfo}
          companyInfo={companyInfo}
          jobInfo={jobInfo}
          setOpenAuthenticationModal={setOpenAuthenticationModal}
          setOpenRefineLetterModal={setOpenRefineLetterModal}
          userEdits={userEdits}
          setUserEdits={setUserEdits}
          handleSubmit={handleSubmit}
          handleRefineLetter={handleRefineLetter}
        />
      ),
    },
    {
      path: '/purchase',
      element: <Products />,
    },
    {
      path: '/thank-you',
      element: <ThankYou />,
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <RouterProvider router={router} />
        <LoadingModal
          open={loading}
          error={error}
          handleClose={handleCloseLoading}
        />
        <AuthenticationModal
          open={openAuthenticationModal}
          handleClose={() => setOpenAuthenticationModal(false)}
        />
        <RefineLetterModal
          open={openRefineLetterModal}
          handleClose={() => setOpenRefineLetterModal(false)}
          handleRefineLetter={handleRefineLetter}
          userEdits={userEdits}
          setUserEdits={setUserEdits}
        />
      </Box>
    </ThemeProvider>
  );
}

export default App;
